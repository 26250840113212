import React,{ useState } from 'react';
import HeaderMobile from '../header-mobile/headerMobile'
// import HeaderProfile from '../../app/containers/Common/Profile/profile';
import { makeStyles } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ucFirst } from '../_helpers';


let Header = () =>{
    const userDetails = useSelector((state) => state.auth.user, shallowEqual);

    // const handleToggle = () => {
    //     setOpen((prevOpen) => !prevOpen);
    // };

    return  (
        <>
            <HeaderMobile userDetails={userDetails} />
            <div id="kt_header" className="header header-fixed">
                <div className={`container-fluid d-flex align-items-stretch justify-content-between`}>
                    <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper"></div>
                    {/* <div className="topbar" onClick={toggleDrawer(state.tabSide, true)}> */}
                    <div className="topbar dropbtn dropdown">
                        <div className="topbar-item">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{`${ucFirst(userDetails.name)}`}</span>
                                <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                                    <span className="symbol-label font-size-h5 font-weight-bold">S</span>
                                </span>
                            </div>
                        </div>
                        <div className="dropdown-content">
                            <NavLink to="/my-profile">
                                <span>My Profile</span>
                            </NavLink>
                            <NavLink to="/logout">
                                <span className="logout-class">Logout</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* <HeaderProfile anchor={state.tabSide} isOpen={state[state.tabSide]} toggleDrawer={toggleDrawer}></HeaderProfile> */}
        </>
    )
}

export default Header;
