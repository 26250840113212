//@ts-check
import React from 'react';
import {toAbsoluteUrl} from '../../layout/_helpers'
import {NavLink} from 'react-router-dom';


let ErrorPage = (props) => {
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{ backgroundImage: `url(${toAbsoluteUrl("media/error/bg6.jpg")})`,}}
            >
                {/* <div className="d-flex flex-column flex-row-fluid text-center">
                <h1
                    className="error-title font-weight-boldest text-white mb-12"
                    style={{ marginTop: "12rem;" }}
                >
                    Oops...
                </h1>
                <p className="display-4 font-weight-bold text-white">
                    Something went wrong. Please try again
                    <br />
                    <NavLink to="/" className="btn btn-light-danger font-weight-bold mt-30">Back To Home</NavLink>
                </p>
                </div> */}
                <div className="d-flex flex-column flex-row-fluid text-center">
                    <h1 className="error-title font-weight-boldest text-white mb-12">Oops...</h1>
                    <p className="display-4 font-weight-bold text-white">Looks like something went wrong.<br />We're working on it</p>
                </div>

            </div>
        </div>
    )
}

export default ErrorPage
