//@ts-check
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./Routes";
import { I18nProviders } from "../layout/i18n";
import {
    LayoutSplashScreen,
    // MaterialThemeProvider
}from "../layout";

 export default function App({ store, persistor, basename }) {
    //  console.log('persistor',persistor)
   return (
     /* Provide Redux store */
     <Provider store={store}>
       {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
       <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
       {/* <PersistGate persistor={persistor} loading={<div>Loading...</div>}> */}
         {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
         <React.Suspense fallback={<LayoutSplashScreen />}>
           {/* Override `basename` (e.g: `homepage` in `package.json`) */}
           <BrowserRouter basename={basename}>
             {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
             {/* <MaterialThemeProvider> */}
               {/* Provide `react-intl` context synchronized with Redux state.  */}
               <I18nProviders>
                 {/* Render routes with provided `Layout`. */}
                 <Routes />
               </I18nProviders>
             {/* </MaterialThemeProvider> */}
           </BrowserRouter>
         </React.Suspense>
       </PersistGate>
     </Provider>
   );
}
