import React from "react";
import ReactDOM from "react-dom";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import { BASE_URL } from  './app/containers/apiUrl';

import "../src/_assets/css/index.css" // Standard version
import "../src/_assets/plugins/keenthemes-icons/font/ki.css";
// import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../src/_assets/plugins/flaticon/flaticon.css";
import "../src/_assets/plugins/flaticon2/flaticon.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css"; // import react-redux-toastr
import 'react-confirm-alert/src/react-confirm-alert.css'; // Delete Confirm alert css


// Datepicker
// import "react-datepicker/dist/react-datepicker.css";

import {
    SplashScreenProvider,
    SubheaderProvider,
} from "./layout";
import { I18nProvider } from "./layout/i18n";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";


const { REACT_APP_PUBLIC_URL } = process.env;

//Setup Axios
axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

_redux.setupAxios(axios, store);

ReactDOM.render(
    <React.StrictMode>
        <I18nProvider>
            <SubheaderProvider>
                <SplashScreenProvider>
                    <Provider store={store}>
                        <App store={store} persistor={persistor} basename={REACT_APP_PUBLIC_URL} />
                        <ReduxToastr
                            timeOut={5000}
                            newestOnTop={true}
                            preventDuplicates
                            position="top-right"
                            getState={(state) => state.toastr} // This is the default
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar
                            closeOnToastrClick
                        />
                    </Provider>
                </SplashScreenProvider>
            </SubheaderProvider>
        </I18nProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

