//@ts-check
import React from 'react';
import {toAbsoluteUrl} from '../../layout/_helpers'
import {NavLink} from 'react-router-dom';

let ErrorPage = (props) => {
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{ backgroundImage: `url(${toAbsoluteUrl("media/error/bg2.jpg")})`,}}
            >
                {/* <div className="d-flex flex-column flex-row-fluid text-center">
                    <h1
                        className="error-title font-weight-boldest text-white mb-12"
                        style={{ marginTop: "12rem;" }}
                    >
                        Oops!
                        Something Went Wrong Here
                    </h1>
                    <p className="display-4 font-weight-bold text-white">
                        <NavLink to="/" className="btn btn-light-danger font-weight-bold mt-30">Back To Home</NavLink>
                    </p>
                </div> */}
                <div className="d-flex flex-row-fluid flex-column justify-content-end align-items-center text-center text-white pb-40">
                    <h1 className="display-1 font-weight-bold">OOPS!</h1>
                    <span className="display-4 font-weight-boldest mb-8">Looks like this is invalid url</span>
                    <p className="display-4 font-weight-bold text-white">
                        <NavLink to="/" className="btn btn-light-danger font-weight-bold mt-30">Back To Home</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage
