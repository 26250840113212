import React, { useEffect,lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../layout/main/layout";
import BasePage from "./BasePage";
// import styled from 'styled-components';
import { shallowEqual, useDispatch,connect, useSelector } from "react-redux";
import {
    Logout,
    AuthPage
} from "./containers/Auth";

import ErrorPage from "../app/components/errorPage";
import NotFoundPage from "../app/components/404Page";
import UnauthorizedPage from "../app/components/unauthorizedPage";

function Routes(props){
    const { isAuthorized,user_type } = useSelector(({ auth }) => ({
            isAuthorized: auth.user != null,
            user_type:auth.user?.user_type
        }),
        shallowEqual
    );

    return (
        <Switch>
            {!isAuthorized ? (
                //Render auth page when user at `/auth` and not authorized.
                <Route><AuthPage /></Route>
            ) : (
                //Otherwise redirect to root page (`/`)
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/logout" component={Logout} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/404" component={NotFoundPage} />
            {/* <Route path="/401" component={UnauthorizedPage} /> */}
            {!isAuthorized ? (
                //Redirect to `/auth` when user is not authorized
                <Redirect to="/auth/login" />
                ) : (
                    <Layout><BasePage /></Layout>
            )}


        </Switch>
    );
}

export default Routes
