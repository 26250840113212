import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    statLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    dashboardStats: [],
    lastError: null,
};

export const callTypes = {
    stats: "stats",
    list: "list",
    action: "action",
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.stats) {
                state.statLoading = false;
            }
            else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else if (action.payload.callType === callTypes.stats) {
                state.statLoading = true;
            }
            else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else if (action.payload.callType === callTypes.stats) {
                state.statLoading = false;
            }
            else {
                state.actionsLoading = false;
            }
        },
        // getDashboardStats
        dashboardStatsFetched: (state, action) => {
            const { entities } = action.payload;
            state.statLoading = false;
            state.dashboardStats = entities;
            state.error = null;
        },
    },
});
