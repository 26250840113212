import React from 'react';
import {NavLink} from 'react-router-dom';
import {toAbsoluteUrl} from '../_helpers/index';
import { ucFirst } from '../_helpers';

let headerMobile = ({userDetails})=>{
    let openSidebar = ()=>{
        let checkClass =  document.getElementById('kt_aside').classList.contains('aside-on');
        if(checkClass){
            document.getElementById('kt_aside').classList.remove('aside-on');
        }else{
            document.getElementById('kt_aside').classList.add('aside-on');
        }
    }

    return (
        <>
            <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed">
                <div>
                    <NavLink  to="#">
                        <img alt="Logo" src={toAbsoluteUrl("media/logos/new-logo.svg")} />
                    </NavLink>
                    <span className="symbol symbol-25 symbol-light-primary">
                    </span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="topbar dropbtn dropdown dropdown-nav-mobile">
                        <div className="topbar-item">
                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                                <span className="text-dark-50 font-weight-bolder font-size-base  mr-3">{`${ucFirst(userDetails.name)}`}</span>
                            </div>
                        </div>
                        <div className="dropdown-content">
                            <NavLink to="/my-profile">
                                <span>My Profile</span>
                            </NavLink>
                            <NavLink to="/logout">
                                <span className="logout-class">Logout</span>
                            </NavLink>
                        </div>
                    </div>
                    <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle" onClick={openSidebar}>
                        <span></span>
                    </button>

                </div>
            </div>
        </>
    )
}

export default headerMobile
