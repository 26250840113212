export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {
            const {
                auth: { authToken },
            } = store.getState();
            if (authToken) {
                // config.headers.Authorization = `Bearer ${authToken}`;
                // config.headers.token = authToken;
                config.withCredentials = true;
                config.headers["token"] = authToken;
            }
            return config;
        },
        (err) => Promise.reject(err)
    );

    axios.interceptors.response.use((response) => {
            return response;
        },(error) => {
            if (error.response && error.response.status === 401) {
                if(error.response.config.url.search('auth/login') == '-1'){
                    window.location.href = "/logout";
                }
                return error.response;
            } else {
                if (error.response && error.response.data) {
                    return error.response;
                } else {
                    return {
                        data: { "status": "failure", "message": "Something went wrong" }
                    }
                }
            }
        }
    );
}
