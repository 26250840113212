import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    faqDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const faqSlice = createSlice({
    name: "faq",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getfaqs
        faqsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getfaqs
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getfaqDetails
        faqDetailsFetched: (state, action) => {
            const { faqDetails } = action.payload;
            state.actionsLoading = false;
            if(faqDetails) delete faqDetails.password;
            // if(faqDetails ) delete faqDetails.service_password;
            state.faqDetails = faqDetails;
            state.error = null;
        },
        // updatefaq
        faqUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.faqDetails = action.payload.faq;
        },
        faqCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        faqDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.faq_id
            );
        },
    },
});
