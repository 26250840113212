import React from  'react';
import ReactLoading from 'react-loading';


let Loader = function({isShow,type="spin",color="#187DE4",height="5%",width="5%"}){
    return (
        isShow && isShow ==true ? (
            <span className="loader-view">
                <ReactLoading type={type} color={color} height={height} width={width} />
            </span>
        ):""
    )
}

export default Loader;
