// export const BASE_URL = "http://localhost:5000" //production base url
// export const BASE_URL = "http://localhost:5000" //production base url
export const BASE_URL = "https://api.findmech.com" //production base url
// export const BASE_URL = "https://181e-2401-4900-1c70-c18a-1d23-817b-2a9c-15f8.ngrok-free.app" //production base url
//export const BASE_URL = process.env.REACT_APP_BASE_URL //production base url
// export const BASE_URL = "http://100.25.232.144:5000" //production base url
// export const CHAT_CONNECTION_URL = "http://100.25.232.144:5000" //local chat connection url
// export const CHAT_CONNECTION_URL = "http://192.168.1.98:5000" //local chat connection url
// export const IMAGE_BASE_URL = "http://100.25.232.144:5000"
console.log("baseurl", BASE_URL);
export const API_VERSION = "/api/v1/"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i


// //This regex only allow numbers
//This regex allows numbers and brackets and plus and hiphen, eg (+91)-12321315-654
export const PHONE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/

export const SOCIAL_SECURITY_CODE_REGEX = /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/


//Admin Auth
export const LOGIN_URL = API_VERSION + "auth/login";
export const DRIVER_REGISTER_URL = API_VERSION + "auth/driver/register";
export const LOGOUT_URL = API_VERSION + "auth/logout";
export const REQUEST_PASSWORD_URL = API_VERSION + "auth/forgotPassword";
export const RESET_PASSWORD_URL = API_VERSION + "auth/resetPassword";


//Admin Profile
export const ADMIN_LOGIN_URL = API_VERSION + "admin/adminLogin";
export const EDIT_ADMIN_PROFILE = API_VERSION + "admin/editProfile";
export const GET_ADMIN_PROFILE = API_VERSION + "admin/getUserProfile";


//Admin supplier
export const GET_ALL_SUPPLIERS_WITH_FILTER = API_VERSION + "admin/supplier/getSuppliersWithFilter";
export const GET_SUPPLIER_PROFILE = API_VERSION + "admin/supplier/getSupplierById";
export const ADD_SUPPLIER = API_VERSION + "admin/supplier/addSupplier";
export const EDIT_SUPPLIER = API_VERSION + "admin/supplier/editSupplier";
export const DELETE_SUPPLIER = API_VERSION + "admin/supplier/removeSupplier";


//Admin Categories
export const GET_ALL_CATEGORYS_WITH_FILTER = API_VERSION + "admin/category/getCategoriesWithFilter";
export const GET_CATEGORY_PROFILE = API_VERSION + "admin/category/getCategoryById";
export const ADD_CATEGORY = API_VERSION + "admin/category/addCategory";
export const EDIT_CATEGORY = API_VERSION + "admin/category/editCategory";
export const DELETE_CATEGORY = API_VERSION + "admin/category/deleteCategory";


//Admin Super Mechanic
export const GET_ALL_SUPER_MECHANICS_WITH_FILTER = API_VERSION + "admin/mechanic/getSuperMechanicsWithFilter";
export const GET_SUPER_MECHANIC_PROFILE = API_VERSION + "admin/mechanic/getSuperMechanicById";
export const ADD_SUPER_MECHANIC = API_VERSION + "admin/mechanic/addSuperMechanic";
export const EDIT_SUPER_MECHANIC = API_VERSION + "admin/mechanic/editSuperMechanic";
export const DELETE_SUPER_MECHANIC = API_VERSION + "admin/mechanic/removeSuperMechanic";


//Admin uSER
export const GET_ALL_USERS_WITH_FILTER = API_VERSION + "admin/USER/getUsersWithFilter";
export const GET_USER_PROFILE = API_VERSION + "admin/USER/getUserById";
export const GET_USER_DETAILS = API_VERSION + "admin/USER/getUserDetailsById";
export const ADD_USER = API_VERSION + "admin/USER/addUser";
export const EDIT_USER = API_VERSION + "admin/USER/editUser";
export const DELETE_USER = API_VERSION + "admin/USER/removeUser";


//Admin Mechanic
export const GET_ALL_MECHANICS_WITH_FILTER = API_VERSION + "admin/mechanic/getMechanicsWithFilter";
export const GET_MECHANIC_PROFILE = API_VERSION + "admin/mechanic/getMechanicById";
export const GET_MECHANIC_DETAIL = API_VERSION + "admin/mechanic/getMechanicDetailsById";
export const ADD_MECHANIC = API_VERSION + "admin/mechanic/addMechanic";
export const EDIT_MECHANIC = API_VERSION + "admin/mechanic/editMechanic";
export const DELETE_MECHANIC = API_VERSION + "admin/mechanic/removeMechanic";
export const GET_ALL_CATEGORY = API_VERSION + "admin/mechanic/getActiveCategories";


//Spare Parts
export const GET_ALL_SPARE_PARTS_WITH_FILTER = API_VERSION + "admin/supplier/getSparePartsWithFilter";
export const GET_SPARE_PART_PROFILE = API_VERSION + "admin/supplier/getSparePartById";
export const ADD_SPARE_PART = API_VERSION + "admin/supplier/addSparePart";
export const EDIT_SPARE_PART = API_VERSION + "admin/supplier/editSparePart";
export const DELETE_SPARE_PART = API_VERSION + "admin/supplier/removeSparePart";

//Car Dealer
export const GET_ALL_CAR_DEALERS_WITH_FILTER = API_VERSION + "admin/dealer/getCarDealersWithFilter";
export const GET_CAR_DEALER_DETAIL = API_VERSION + "admin/dealer/getCarDealerById";
export const ADD_CAR_DEALER = API_VERSION + "admin/dealer/addCarDealer";
export const EDIT_CAR_DEALER = API_VERSION + "admin/dealer/editCarDealer";
export const DELETE_CAR_DEALER = API_VERSION + "admin/dealer/removeCarDealer";


//Faq
export const GET_FAQ_DETAIL = API_VERSION + "admin/faq/getFaqById";
export const GET_ALL_FAQS_WITH_FILTER = API_VERSION + "admin/faq/getFaqsWithFilter";
export const ADD_FAQ = API_VERSION + "admin/faq/addFaq";
export const EDIT_FAQ = API_VERSION + "admin/faq/editFaq";
export const DELETE_FAQ = API_VERSION + "admin/faq/removeFaq";


//Cms
export const GET_CMS_DETAILS = API_VERSION + "admin/cms/get-cms";
export const EDIT_CMS = API_VERSION + "admin/cms/update-cms";
export const DELETE_CMS = API_VERSION + "admin/cms/remove-cms";


//Cms
export const GET_CONTACT_DETAILS = API_VERSION + "admin/contact/getContact";
export const EDIT_CONTACT = API_VERSION + "admin/contact/updateContact";
export const DELETE_CONTACT = API_VERSION + "admin/contact/removeContact";


//Admin Dashboard
export const GET_DASHBOARD_STATS = API_VERSION + "carrier/dashboard/getDashboardData";

//Admin Driver
export const GET_DRIVER_PROFILE_DETAILS = API_VERSION + "driver/getDriverProfile";

//Admin Notification
export const GET_ALL_USERS_WITH_FILTER_FOR_NOTIFICATION = API_VERSION + "admin/user/getAllUsersWithFilterForNotification";
export const SEND_NOTIFICATION = API_VERSION + "admin/user/sendNotificationToAllUsers";


//Setting
export const UPDATE_RADIUS = API_VERSION + "admin/radius/update";
export const GET_UPDATED_RADIUS = API_VERSION + "admin/radius/getRadius";


// Subscription Add
// export const ADD_ALL_PENDING_SUBSCRIPTION_REQUESTS = API_VERSION + "admin/USER/getUsersPendingSubscription";

export const CREATE_NEW_SUBSCRIPTION = API_VERSION + "subscription/create";
export const GET_ALL_SUBSCRIPTION = API_VERSION + "subscription/get";
export const DELETE_SUBSCRIPTION = API_VERSION + "subscription/delete";


// Subscription
export const GET_ALL_PENDING_SUBSCRIPTION_REQUESTS = API_VERSION + "admin/USER/getUsersPendingSubscription";

export const GET_SUBSCRIPTION = API_VERSION + "subscription/get";
export const BUY_SUBSCRIPTION = API_VERSION + "subscription/buy";
export const APPROVE_SUBSCRIPTION = API_VERSION + "subscription/approve";