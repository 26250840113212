import React from "react";
import {useLang} from "./i18n";
import {IntlProvider} from "react-intl";
// import "@formatjs/intl-relativetimeformat/polyfill";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import enMessages from "./messages/en";
import esMessages from "./messages/es";

const allMessages = {
    en: enMessages,
    es: esMessages
};

export function I18nProviders({ children }) {
    const locale = useLang();
    const messages = allMessages[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
}
