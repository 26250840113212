import React, { useEffect, useState,useCallback } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import {toastr} from 'react-redux-toastr'




let isRequested = false //when not request forgot password request,, true,false
function ForgotPassword(props) {

    let [values,setState] = useState({
        email:"",
        loading:false,
        isRequested:false
    })


    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
      } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onSubmit = handleSubmit((data) => {
        showHideLoader(true);
        data.isRequested = values.isRequested //addign value that check password request check
        requestPassword(data).then((response) => {
            let { data } = response
            showHideLoader(false);
            if(response.status == 200){
                console.log('data',data)
                toastr.success('Success',data.message);
                if(values.isRequested){
                    props.history.push('/auth/login')
                }else{
                    setState({
                        ...values,
                        isRequested:true
                    })
                }
            }else{
                toastr.error('Error',data.message);
                return;
            }
        }).catch((error) => {
            console.log('error',error)
            showHideLoader(false);
            toastr.error('Error',"Something went wrong!");
            return;
        });
    });

    return (
        <>
            <div className="login-form login-forgot" style={{ display: "block" }}>
                <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h5 font-size-h2-lg">Driver Recruitment System</h3>
                </div>
                <div className="text-center mb-10 mb-lg-10">
                    <h3 className="font-size-h1">Forgotten Password ?</h3>
                    <div className="text-muted font-weight-bold">
                        Enter your email to reset your password
                    </div>
                </div>
                <form className="form" onSubmit={onSubmit}>
                    {
                        values.isRequested==false && (
                            <div className="form-group">
                                <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                                <input
                                    className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                    type="email"
                                    name="email"
                                    {...register("email",{
                                        required: "Email is required*",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Enter a valid email address",
                                        },
                                    })}
                                    defaultValue={values.email}
                                />
                                {errors.email && <p className="error">{errors.email.message}</p>}
                            </div>
                        )
                    }
                    {
                        values.isRequested && (
                            <>
                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">Otp</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        type="text"
                                        name="otp"
                                        {...register("otp",{
                                            required: "Otp is required*",
                                        })}
                                        defaultValue={values.otp}
                                    />
                                    {errors.otp && <p className="error">{errors.otp.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">Password</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        type="password"
                                        name="password"
                                        {...register("password",{
                                            required: "Password is required*",
                                            minLength: { value: 8, message: "Password should be at least 8 digit*" }
                                        })}
                                        defaultValue={values.password}
                                    />
                                    {errors.password && <p className="error">{errors.password.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label className="font-size-h6 font-weight-bolder text-dark">Confirm Password</label>
                                    <input
                                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                                        type="password"
                                        name="confirm_password"
                                        {...register("confirm_password",{
                                            required: "Confirm Password is required*",
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                const { password } = getValues();
                                                return password === value || "Passwords should match!";
                                                }
                                            }
                                        })}
                                        defaultValue={values.confirm_password}
                                    />
                                    {errors.confirm_password && <p className="error">{errors.confirm_password.message}</p>}
                                </div>
                            </>
                        )
                    }
                    <button
                    type="submit"
                    id="kt_login_singin_form_submit_button"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                    >
                        Submit
                    </button>
                    <NavLink
                    to="/auth/login"
                    id="kt_login_singin_form_submit_button"
                    className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                    >
                        Cancel
                    </NavLink>
                </form>
                <Loader isShow={values.loading}/>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
