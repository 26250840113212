//@ts-check
import axios from "axios";
import {
    ADMIN_LOGIN_URL,
    LOGOUT_URL,
    REQUEST_PASSWORD_URL,
    EDIT_ADMIN_PROFILE,
    RESET_PASSWORD_URL,
    EDIT_SUPPLIER,
    GET_SUPPLIER_PROFILE,
    GET_DRIVER_PROFILE_DETAILS,
    GET_ADMIN_PROFILE
} from '../../apiUrl.js'

export const ME_URL = "api/me";

export function login(email, password,firebase_token) {
    return axios.post(ADMIN_LOGIN_URL, { email, password,firebase_token });
}

export function updateUserProfile(data,user_type) {
    let url = EDIT_ADMIN_PROFILE
    if(user_type=='SUPPLIER' || user_type=='SUB_SUPPLIER'){
        url = EDIT_SUPPLIER
    }
    return axios.post(url,data);
}

export function getUserProfile(user_type) {
    let url;
    if(user_type=='SUPPLIER' || user_type=='SUB_SUPPLIER'){
        url = GET_SUPPLIER_PROFILE
        return axios.get(url);
    }else if(user_type=='DRIVER' || user_type=='FLEET_OWNER'){
        url = GET_DRIVER_PROFILE_DETAILS
        return axios.get(url);
    }else if(user_type=='ADMIN' || user_type=='SUBADMIN'){
        url = GET_ADMIN_PROFILE
        return axios.get(url);
    }
    //when user is admin then return empty value
    return new Promise((reject,resolve)=>{
        resolve({status:"success",message:"Empty"})
    })
}

export function requestPassword(data) {
    let url = REQUEST_PASSWORD_URL
    if(data.isRequested){
        url = RESET_PASSWORD_URL
    }
    delete data['isRequested']
    return axios.post(url,data);
}

export function logout() {
    // return axios.post(LOGOUT_URL, {});
    return new Promise((resolve, reject) => {resolve({})});
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}
