import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Loader from '../../Common/Loader/loader'
import { useForm } from 'react-hook-form';
import { login } from "../_redux/authCrud";
import {toastr} from 'react-redux-toastr'
import { EMAIL_REGEX } from '../../apiUrl'



function Login(props) {
    let [values,setState] = useState({
        email:"",
        password:"",
        loading:false
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onSubmit = handleSubmit((data) => {
        showHideLoader(true);
        let firebase_token = "dummyToken"
        login(data.email, data.password,firebase_token).then((response) => {
            showHideLoader(false);
            if(response.data) {
                let {data} = response
                if(response.status=='200'){
                    if (!data.token) {
                        toastr.error('Error',data.message)
                        return;
                    }else{
                        props.login(data.token, data.data);
                        toastr.success('Success',data.message)
                    }
                }else{
                    toastr.error('Error',data.message)
                    return;
                }
            }
        }).catch((error) => {
            showHideLoader(false);
            console.log('error',error)
            toastr.error('Error',error.message)
        });
    });

    return (
        <>
            <form className="form" noValidate="noValidate" onSubmit={onSubmit}>
                <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-center text-dark font-size-h5 font-size-h2-lg">Login Account</h3>
                    <p className="text-muted text-center font-weight-bold">Enter your email and password</p>
                </div>
                <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                    <input
                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                        type="text"
                        name="email"
                        {...register("email",{
                            required: "Email is required*",
                            // pattern: {
                            //     value:EMAIL_REGEX,
                            //     message: "Enter a valid email address",
                            // },
                        })}
                        defaultValue={values.email}
                    />
                    {errors.email && <p className="error">{errors.email.message}</p>}
                </div>
                <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                    <input
                        className="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
                        type="password"
                        name="password"
                        // onChange={handleChange}
                        defaultValue={values.password}
                        autoComplete="off"
                        {...register("password",{
                            required: "Password is required*",
                        })}
                    />
                    {errors.password && <p className="error">{errors.password.message}</p>}
                </div>
                {/* <div className="d-flex justify-content-between mt-n5">
                    <label></label>
                    <NavLink
                        to="/forgot-password"
                        className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                        id="kt_login_forgot"
                    >
                        Forgot Password ?
                    </NavLink>
                </div> */}
                <button
                type="submit"
                id="kt_login_singin_form_submit_button"
                className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                >
                    Sign In
                </button>
            </form>
            {/* <div className="d-block py-3 px-3 pt-5 text-dark-50 text-center font-size-45"> Or Sign in with </div>
            <div className="text-center">
                <button type="button" className="btn btn-secondary text-dark-75 font-weight-bold  my-3 mx-2">
                <i className="fab fa-google text-danger"></i>Google</button>
                <button type="button" className="btn btn-secondary text-dark-75  font-weight-bold my-3 mx-2">
                <i className="fab fa-facebook text-primary"></i>Facebook</button>
                <button type="button" className="btn btn-secondary text-dark-75 font-weight-bold my-3 mx-2">
                <i className="fab fa-amazon text-success"></i>Amazon</button>
                <button type="button" className="btn btn-secondary text-dark-75 font-weight-bold my-3 mx-2">
                <i className="fab fa-apple "></i>Apple</button>
            </div> */}
            <Loader isShow={values.loading}/>
        </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
// export default connect(null, auth.actions)(Login);
