import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    categoryDetails: undefined,
    lastError: null,
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const categorySlice = createSlice({
    name: "category",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        endCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        // getcategorys
        categorysFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.entities = entities;
            state.totalCount = totalCount;
            state.error = null;
        }, // getcategorys
        officeFetched: (state, action) => {
            const { entities } = action.payload;
            state.listLoading = false;
            state.offices = entities;
            state.error = null;
        },
        //getcategoryDetails
        categoryDetailsFetched: (state, action) => {
            const { categoryDetails } = action.payload;
            state.actionsLoading = false;
            if(categoryDetails) delete categoryDetails.password;
            // if(categoryDetails ) delete categoryDetails.service_password;
            state.categoryDetails = categoryDetails;
            state.error = null;
        },
        // updatecategory
        categoryUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            // state.categoryDetails = action.payload.category;
        },
        categoryCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.customer); //push in existing list
        },
        // deleteCustomer
        categoryDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el._id !== action.payload.category_id
            );
        },
    },
});
