import React,{useEffect} from "react";
import Header from '../header/header';
import Footer from '../footer/footer';
// import  ScrollTop  from "../extras/ScrollTop";
import Aside from  '../sidebar/index';
import { useLocation } from "react-router";
import {checkIsActive}  from '../_helpers/index'


// import GlobalStyle from '../../global-styles';

export function Layout({ children }) {
    //This class is only use for chat page
    // const location = useLocation();
    // useEffect(() => {
    //     if(checkIsActive(location,'/chat')){
    //         document.getElementById('kt_wrapper').classList.add('chat_header');
    //         document.getElementById('kt_content').classList.add('pt-0');
    //         document.getElementById('kt_content').classList.add('pb-0');
    //     }else{
    //         document.getElementById('kt_wrapper').classList.remove('chat_header');
    //         document.getElementById('kt_content').classList.remove('pt-0');
    //         document.getElementById('kt_content').classList.remove('pb-0');
    //     }
    // },[location]);
   return(
        <>
        <div className="d-flex flex-column flex-root">
			{/*begin::Page*/}
			<div className="d-flex flex-row flex-column-fluid page">
                <Aside />
				{/*begin::Wrapper*/}
				<div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
					<Header />
					{/*begin::Content*/}
					<div id="kt_content" className={`content d-flex flex-column flex-column-fluid kt-min-height`}>
					{/*begin::Entry*/}
                    <div className="d-flex flex-column-fluid">
                        {/*begin::Container*/}
                        <div className="content_container col-12 py-0 px-0">
                            {children}
                        </div>
                        {/*end::Container*/}
                    </div>
					{/*end::Entry*/}
					</div>
					{/*end::Content*/}
                    <Footer />
				</div>
				{/*end::Wrapper*/}
			</div>
			{/*end::Page*/}
		</div>
        {/* <GlobalStyle />  */}
		{/* <ScrollTop /> */}
		{/*end::Main*/}
    </>
	);
}
