export const ucFirst = (string) =>{
    if(string && typeof string == 'string'){
        return string.charAt(0).toUpperCase() + string.slice(1)
    }else{
        return string;
    }
}

export const removeUnderscoretoReadingMode = (string) =>{
    if(string && typeof string == 'string'){
        let text = string.replaceAll('_',' ').toLocaleLowerCase()
        return text.charAt(0).toUpperCase() + text.slice(1)
    }else{
        return string;
    }
}
